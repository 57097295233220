import { SdkManager, SdkManagerBuilder } from '@aps_sdk/autodesk-sdkmanager'
import { AuthenticationClient, Scopes } from '@aps_sdk/authentication'
import axios from 'axios'

const sdkmanager: SdkManager = SdkManagerBuilder.create().build()
const authenticationClient = new AuthenticationClient(sdkmanager)

export const twoLeggedViewerInitializer = {
  env: 'AutodeskProduction2',
  api: 'streamingV2',
  useConsolidation: true,
  consolidationMemoryLimit: 150 * 1024 * 1024,
  getAccessToken: async tokenReady => {
    const token = await getTwoLeggedToken()
    tokenReady(token, 3600)
  }
}

export const getTwoLeggedToken = async () => {
  try {
    const creds = btoa(
      `${process.env.VUE_APP_FORGE_CLIENT_ID}:${process.env.VUE_APP_FORGE_SECRET}`
    )

    const scopes = [
      Scopes.ViewablesRead,
      Scopes.AccountRead,
      Scopes.AccountWrite,
      Scopes.BucketCreate,
      Scopes.BucketRead,
      Scopes.BucketDelete,
      Scopes.BucketUpdate,
      Scopes.CodeAll,
      Scopes.DataRead
    ]

    const data = new URLSearchParams()
    data.append('grant_type', 'client_credentials')

    for (const s of scopes) {
      data.append('scope', s)
    }

    const request = await axios.post(
      'https://developer.api.autodesk.com/authentication/v2/token',
      data,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept': 'application/json',
          'Authorization': `Basic ${creds}`
        }
      }
    )

    return request.data.access_token
  } catch (error) {
    console.error(error)
  }
}
