
import { Component, Prop, Vue } from 'vue-property-decorator'
import IconCopy from '@/assets/icons/clipboard.svg'
import IconMagnifying from '@/assets/icons/magnifying-glass.svg'
import IconMap from '@/assets/icons/box-padding.svg'
import IconImage from '@/assets/icons/photo.svg'
import IconLike from '@/assets/icons/heart.svg'
import Store from '@/store/modules/Store'

@Component({
  components: { IconCopy, IconMagnifying, IconMap, IconImage, IconLike }
})
export default class SelectionTab extends Vue {
  @Prop() selected: any
  @Prop() selectedProperties: any
  @Prop() modelName: string
  @Prop() showBimSelections: boolean

  private showDataBIM: boolean = false
  private showDataNoBIM: boolean = true
  private isCopyDataBIM: boolean = false
  private isCopyDataNoBIM: boolean = false
  private indexTotal: number | string = null
  private regexp: RegExp = /^\d+(,\d+)*$/
  private like: boolean = false

  created() {
    const headers = this.selectedProperties.headers
      .filter((head: { origin: string }) => head.origin !== '')
      .map(h => h.header)

    for (const header of headers) {
      if (header === 'Total € (w/o VAT - KS)') {
        this.indexTotal = headers.indexOf(header)
      }
    }
  }

  private get headersBIM(): any[] {
    return this.selectedProperties.headers.filter(h => h.origin === '')
  }

  private copyDataBIM() {
    const headersBIM = this.selectedProperties.headers.filter(
      h => h.origin === ''
    )

    const row = []

    headersBIM.forEach((_, i) => {
      row.push(this.selectedProperties.row[i])
    })

    navigator.clipboard.writeText(
      JSON.stringify({
        headers: headersBIM.map(h => h.header),
        row
      })
    )

    this.writeClipboard(headersBIM, row)
  }

  private copyDataNoBIM() {
    const headersBIM = this.selectedProperties.headers.filter(
      h => h.origin === ''
    )

    const headersNoBIM = this.selectedProperties.headers.filter(
      h => h.origin !== ''
    )

    const row = []

    headersNoBIM.forEach((_, i) => {
      row.push(this.selectedProperties.row[headersBIM.length + i])
    })

    this.writeClipboard(headersNoBIM, row)
  }

  private writeClipboard(headers, row) {
    navigator.clipboard.writeText(
      JSON.stringify({
        headers: headers.map(h => h.header),
        row
      })
    )

    this.$toasted.success(this.$i18n.t('dataCopied') as string)
  }

  private lookAtObject() {
    this.$root.$emit('look-at-object', this.modelName, this.selected)
  }

  private setValue(value: string): string {
    let val

    if (typeof value === 'string') {
      val = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    } else {
      val = value
    }

    return `${val} €`
  }

  private getValue(index: any, header: string) {
    let value = this.selectedProperties.row[this.headersBIM.length + index]

    const m2Headers = ['Useful', 'Kronos Surface', '€/m2 Surf. Kronos']

    const test = this.regexp.test(value)

    if (test && typeof value === 'string') {
      value = value.replace(',', '.')
    }

    return m2Headers.some(m => m === header)
      ? `${Number(value).toFixed(2)} m²`
      : value
  }

  private get url(): string {
    const headerIndex = this.selectedProperties.headers.findIndex(
      h => h.header === 'URL PLANO'
    )

    if (this.selectedProperties.row[headerIndex]) {
      return this.selectedProperties.row[headerIndex]
    } else {
      const index = this.selectedProperties.headers.findIndex(
        h => h.header === 'URL'
      )

      return this.selectedProperties.row[index]
    }
  }

  private goUrlPlane() {
    const url = this.url

    window.open(url, 'plane_')
  }

  private go360Image() {
    const headerIndex = this.selectedProperties.headers.findIndex(
      h => h.header === 'Url_Foto360'
    )

    const url = this.selectedProperties.row[headerIndex]

    this.$root.$emit('show-360-modal', url)
  }

  private get isLike(): boolean {
    return Store.urlLikes.includes(this.url)
  }

  private handleLike() {
    if (!this.isLike) {
      Store.pushToUrlLikes(this.url)
    } else {
      Store.removeFromUrlLikes(this.url)
    }
  }
}
