import makeUrn from '../../helpers/makeUrn'
// @ts-ignore
import Document = Autodesk.Viewing.Document
import getSelectionsData from '@/controllers/dataManagement/getSelectionsData'
import setSelections from '@/controllers/dataManagement/setSelections'

export default (
  models: any[],
  // @ts-ignore
  viewer: Autodesk.Viewing.GuiViewer3D,
  viewable: any = null
) => {
  models.forEach(model => {
    const urn = `urn:${makeUrn(model.objectId)}`

    console.log('⭐urn⭐', urn)

    // @ts-ignore
    Autodesk.Viewing.Document.load(
      urn,
      async doc => {
        const selectedViewable = viewable
          ? getViewables(doc).find(view => view.data.name === viewable.name)
          : getViewables(doc)[0]

        await loadDocument(viewer, doc, selectedViewable)
      },
      error => {
        console.error('error', error)
        throw new Error('error-load-models')
      }
    )
  })

  // Registra nueva opción en menú contextual
  viewer.registerContextMenuCallback('show-data', (menu, status) => {
    if (status.hasSelected) {
      menu.push({
        title: 'Mostrar datos asociados',
        target: async () => {
          const selections = await setSelections(viewer)

          await getSelectionsData(selections)
        }
      })
    }
  })
}

export const loadDocument = async (
  // @ts-ignore
  viewer: Autodesk.Viewing.GuiViewer3D,
  doc: Document,
  viewable: any
) => {
  const url = doc.getViewablePath(viewable)

  if (url) {
    const load = viewer.loadDocumentNode(doc, viewable, {
      acmSessionId: doc.getAcmSessionId(url),
      keepCurrentModels: true,
      skipHiddenFragments: true,
      isAEC: true,
      withCredentials: false,
      // @ts-ignore
      globalOffset: new THREE.Vector4(0, 0, 0, 1)
    })

    if (load) {
      await doc.downloadAecModelData()
      await viewer.loadExtension('Autodesk.AEC.LevelsExtension')
    }
  } else {
    document.dispatchEvent(new CustomEvent('increase-loading-model'))
  }
}

// @ts-ignore
export const getViewables = (doc: Autodesk.Viewing.Document) => {
  const roles = ['3d', '2d']
  const rootItem = doc.getRoot()
  let items = []
  const roleArray = roles ? (Array.isArray(roles) ? roles : [roles]) : []

  roleArray.forEach(role => {
    items = [...items, ...rootItem.search({ type: 'geometry', role })]
  })

  items.forEach(item => {
    if (item.data.hasThumbnail) {
      item.data.thumbnail = doc?.getThumbnailPath(item.data.guid, 400, 400)
    }
  })

  return items
}
